<template>
  <div class="hello">
    <h1>Statistik</h1>

    <p><b>Anzahl registrierter Helfer: {{ this.stats.registeredPersons }}</b></p>

    <h3>Alter:</h3>
    <p>
      Anzahl <AgeHint :ageId="1" />: {{ this.stats.age0 }}
      <br />
      Anzahl <AgeHint :ageId="2" />: {{ this.stats.age1 }}
      <br />
      Anzahl <AgeHint :ageId="3" />: {{ this.stats.age2 }}
    </p>
  </div>
</template>

<script>
import { APIService } from "../APIService";
import AgeHint from "../components/AgeHint.vue";

const apiService = new APIService("test", "test");

export default {
  name: "StatisticsView",

  components: {AgeHint},

  data() {
    return {
      stats: ""
    };
  },

  methods: {
    getStats() {
      apiService.getStats().then(data => {
        this.stats = data;
      });
    }
  },

  mounted() {
    this.getStats();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
