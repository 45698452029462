import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
//import RegisteredPersons from '../views/RegisteredPersons.vue'
import RegisteredPersons2 from '../views/RegisteredPersons2.vue'
import LoginComponent from '../components/Login.vue'
import StatisticsView from '../views/Statistics.vue'
import RegisterView from '../views/Register.vue'
import Events from '../views/Events.vue'
import EventPart from '../views/EventPart.vue'
import EditSectionGroup from '../views/EditSectionGroup.vue'
import EditEvent from '../views/EditEvent.vue'
import SectionGroup from '../views/SectionGroup.vue'
import EditSection from '../views/EditSection.vue'
import EditPosition from '../views/EditPosition.vue'
import EditShift from '../views/EditShift.vue'
import Person from '../views/Person.vue'
import AvailablePersons from '../views/AvailablePersons.vue'
import AssignmentsView from '../views/Assignments.vue'
import DirtyAssignments from '../views/DirtyAssignments.vue'
import MailStatusView from '../views/MailStatus.vue'
import ModifiedUsers from '../views/ModifiedUsers.vue'
import WorkplanView2 from '../views/Workplan2.vue'

Vue.use(VueRouter)
/*function requireAuth (to, from, next) {
  const username = localStorage.getItem('username');

  if(username == "philipp") {
    next();
  } else if (username == "programm") {
    next();
  }
}*/

const routes = [
  {
    path: '/',
    redirect: {
      name: "personList2"
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent
  },  
  {
    path: '/events',
    name: 'events',
    component: Events
  },  
  /*{
    path: '/personlist',
    name: 'personList',
    component: RegisteredPersons
  },*/
  {
    path: '/personlist2',
    name: 'personList2',
    component: RegisteredPersons2
  },
  {
    path: '/assignments',
    name: 'assignments',
    component: AssignmentsView
  },
  {
    path: '/dirty',
    name: 'dirtyAssignments',
    component: DirtyAssignments
  },  
  {
    path: '/mailstatus',
    name: 'mailstatus',
    component: MailStatusView
  },
  {
    path: '/modified',
    name: 'modifiedUsers',
    component: ModifiedUsers
  },  
  {
    path: '/availablePersons',
    name: 'availablePersons',
    component: AvailablePersons
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },  
  {
    path: '/stats',
    name: 'statistics',
    component: StatisticsView
  }, 
  {
    path: '/plan2',
    name: 'Workplan2',
    component: WorkplanView2
  },    
  {
    path: '/structure',
    name: 'structure',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "structure" */ '../views/Structure.vue')
  },
  {
    path: '/plan',
    name: 'workplan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "structure" */ '../views/Workplan.vue')
  },  
  {
    path: '/eventparts/:id',
    name: 'eventPart',
    component: EventPart
  }, 
  {
    path: '/editsectiongroup/:id',
    name: 'editSectionGroup',
    component: EditSectionGroup
  }, 
  {
    path: '/editevent/:id',
    name: 'editEvent',
    component: EditEvent
  },   
  {
    path: '/sectiongroup/:id',
    name: 'sectionGroup',
    component: SectionGroup
  },
  {
    path: '/editsection/:id',
    name: 'editSection',
    component: EditSection
  }, 
  {
    path: '/editposition/:id',
    name: 'editPosition',
    component: EditPosition
  }, 
  {
    path: '/editshift/:id',
    name: 'editShift',
    component: EditShift
  }, 
  {
    path: '/person/:id',
    name: 'person',
    component: Person
  }            
]

const router = new VueRouter({
  routes
})

export default router
