<template>
  <div>
    <h1>Aktive Änderungen - {{ numberOfChanges }}
    &nbsp;<img style="cursor: pointer" @click="getWorkplan()" src="../assets/pdf-icon.png" width="35pt"/></h1>
    <i>Diese Seite zeigt alle Personen deren letzter Arbeitsplan nicht mehr aktuell ist.</i>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">ID</th>
          <th>Name</th>
          <th>Ort</th>
          <th>eMail</th>
          <th>Wer?</th>
          <th>Wann?</th>
          <th>Reset?</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="person in assignments" :key="person.id">
          <td>{{ person.id }}</td>
          <td><router-link
            :to="{ name: 'person', params: { id: person.id, name: person.displayName }}"
          >{{ person.displayName}}</router-link></td>
          <!--<td><router-link
              :to="{ name: 'eventPart', params: { id: assignment.shiftAssignment.shift.eventPartId, partName: assignment.shiftAssignment.shift.eventPartName }}"
            >{{ assignment.shiftAssignment.shift.eventPartName }}</router-link>
          </td>//-->
          <td>{{ person.city }}</td>
          <td>{{ person.email }}</td>
          <td>{{ person.modificationUser }}</td>
          <td>{{ person.updatedAt | formatDate}}</td>
          <td>
            <b-button
              class="btn btn-danger"
              icon
              v-confirm="{
                        loader: false,
                        ok: dialog => resetModified(dialog, person),
                        cancel: doNothing,
                        message: 'Modified state von ' + person.displayName + ' wirklich reseten?'}"
            >X</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";
import moment from "moment";

const apiService = new APIService();

export default {
  name: "AssignmentsView",

  components: {},

  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD.MM.YYYY HH:mm");
      }
    }
  },

  data() {
    return {
      assignments: [],
      numberOfChanges: 0
    };
  },
  methods: {
    doNothing: function() {
      // Do nothing
      console.log("No");
    },
    getWorkplan() {
      apiService.getWorkplanExportFull(true);
    },     
    resetModified(dialog, asm) {
      apiService.resetModifiedById(asm.id).then(response => {
        if (response.status == 200) {
          const index = this.assignments.findIndex(post => post.id === asm.id); // find the section index
          if (~index)
            // if the post exists in array
            this.assignments.splice(index, 1); //delete the section
        }
      });
      dialog.close();
    }
  },
  created() {
    apiService.getModifiedUsers().then(data => {
      this.assignments = data;
      this.numberOfChanges = data.length;
    });
  }
};
</script>