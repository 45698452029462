<template>
  <div class="hello">
    <h1>Helfer - {{ numberOfEvents }}</h1>

    <b-form-fieldset label-text-align="right" horizontal label="Filter:" class="col-4">
      <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Filtertext"></b-form-input>
    </b-form-fieldset>

    <b-modal
      id="modal-confirm"
      size="sm"
      centered
      title="Löschen?"
      @ok="deletePerson(itemToDelete)"
    >
      <template
        v-slot:default="{  }"
      >{{ itemToDelete.firstName }} {{ itemToDelete.lastName }} löschen?</template>
    </b-modal>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="persons-table"
    ></b-pagination>

    <b-table
      show-empty
      id="persons-table"
      :items="persons"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :head-variant="headVariant"
      :filter-included-fields="filterOn"
      class="table table-bordered table-hover table-striped"
      @filtered="onFiltered"
    >
      <template v-slot:cell(name)="row">
        <router-link
          :to="{ name: 'person', params: { id: row.item.id, name: row.item.displayName }}"
        >{{ row.item.lastName }} {{ row.item.firstName }}</router-link>
      </template>
      <template v-slot:cell(teamHead)="row">
        {{ getTeamHead(row.item.teamHead) }}
      </template>
      <template v-slot:cell(age)="row">
        <AgeHint :ageId="row.item.age" />
      </template>         
      <template v-slot:cell(actions)="row">
        <button
          size="sm"
          v-b-modal.modal-confirm
          class="btn btn-danger"
          @click="itemToDelete=row.item"
        >X</button>&nbsp;
        <button size="sm" class="btn btn-warning" @click="editPerson(row.item)">o</button>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="persons-table"
    ></b-pagination>    
  </div>
</template>

<script>
import { APIService } from "../APIService";
import AgeHint from "../components/AgeHint.vue";

const apiService = new APIService();

export default {
  name: "RegisteredPersons",

  props: {
    msg: String
  },

  components: {AgeHint},

  data() {
    return {
      itemToDelete: null,
      fields: [
        { key: "id", sortable: true },
        {
          key: "name",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            //console.log(value);
            return item.lastName;
          }
        },
        "email",
        "phone",
        { key: "city", sortable: true },
        { key: "groupName", sortable: true },
        { key: "teamHead", sortable: true, label: "Team-Head"},
        { key: "age", sortable: true, label: "Alter"},
        { key: "actions", label: "Aktionen" }
      ],
      headVariant: "dark",
      filterOn: ["id", "firstName", "lastName", "groupName"],
      persons: [],
      numberOfEvents: 0,
      totalRows: 0,
      perPage: 25,
      filter: "",
      currentPage: 1
    };
  },
  watch: {
    /*filter(newVal, oldVal) {
      console.log("watch filter:", newVal, oldVal);
    }*/
  },
  methods: {
    deletePerson(person) {
      console.log("Delete: " + person.firstName);
      apiService.deletePerson(person).then(response => {
        if (response.status == 200) {
          const index = this.persons.findIndex(post => post.id === person.id); // find the post index
          if (~index)
            // if the post exists in array
            this.persons.splice(index, 1); //delete the post

          this.getEvents();
        }
      });
    },
    editPerson(person) {
      //console.log(person);
      this.$router.push({
        name: "register",
        params: {
          person: person
        }
      });
    },   
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getEvents() {
      apiService.getRawPersons().then(data => {
        this.persons = data;
        this.numberOfEvents = data.length;
        this.totalRows = data.length;
        console.log("Count: " + this.numberOfEvents + ", " + this.persons[0]);
      });
    },
    getTeamHead(person) {
      if (person != null) {
        return person.id + ": " + person.lastName + " " + person.firstName;
      }
      return "-";
    },
    doNothing: function() {
      // Do nothing
      console.log("No");
    }
  },

  mounted() {
    this.getEvents();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
