import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
