<template>
  <div id="app">
    <div id="nav">
      <router-link v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout | </router-link>
      <!--<router-link to="/personlist">Helfer-Meldungen</router-link>|//-->
      <router-link to="/personlist2">Helferliste</router-link> |
      <router-link to="/register">Registrierung</router-link> |
      <router-link to="/stats">Statistik</router-link> |
      <router-link to="/events">Veranstaltungen</router-link> |
      <router-link to="/assignments">Letzte Einteilungen</router-link>    
      <!--<router-link to="/modified">Aktive Änderungen</router-link> |-->
    </div>
    <router-view @authenticated="setAuthenticated" />
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import VuejsDialog from 'vuejs-dialog';

Vue.use(BootstrapVue);
Vue.use(Multiselect);
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: 'Löschen',
  cancelText: 'Abbrechen'
});

Vue.component("MultiselectComponent", Multiselect);

export default {
  name: "App",
  data() {
    return {
      authenticated: false
    };
  },
  mounted() {
    if (!this.authenticated) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
      this.$emit("authenticated", false);
      localStorage.setItem("username", null);
      localStorage.setItem("password", null);
    }
  }
};
</script>

<style>
body {
  background-color: #f0f0f0;
}
h1 {
  padding: 0;
  margin-top: 0;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
