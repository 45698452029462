/* eslint-disable */
<template>
  <div class="hello">
    <h1>
      <span v-if="mode == 'new'">neue Veranstaltung &nbsp;</span>
      <span v-if="mode == 'edit'">Veranstaltung bearbeiten ({{ model.name }}) &nbsp;</span>
    </h1>

    <div class="container" id="app" v-if="wizardStatus.seen">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="panel-body">
            <vue-form-generator :model="model" :schema="sectionGroupSchema" :options="formOptions"
              ref="registerForm"></vue-form-generator>

            <button class="btn btn-lg btn-primary" @click="onValidate">Speichern</button>
          </div>
        </div>
      </div>
    </div>

    <div id="doneScreen" v-if="wizardStatus.doneMsg">
      <b>Event wurde erfolgreich gespeichert.</b>
    </div>
    <div id="doneScreen" v-if="wizardStatus.errorMsg">
      <b>Hoppla, da ist was schief gelaufen.</b>
    </div>
    <div id="loadingScreen" v-if="wizardStatus.loading">
      <h2>Speichern...</h2>
      <img alt="Loading..." style="width: 26%" src="../assets/loadinganimation.gif" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueFormGenerator from "vue-form-generator";

import { APIService } from "../APIService";

import "vue-form-generator/dist/vfg.css";

Vue.use(VueFormGenerator);

VueFormGenerator.validators.resources.fieldIsRequired =
  "Dieses Feld ist ein Pflichtfeld.";

const apiService = new APIService();

function getDefaultData() {
  return {
    mode: "new",
    wizardStatus: {
      seen: true,
      doneMsg: false,
      errorMsg: false,
      loading: false
    },
    model: {
      name: ""
    },
    formOptions: {
      validationErrorClass: "has-error",
      validationSuccessClass: "has-success",
      validateAfterChanged: true,
      validateAfterLoad: false
    },
    sectionGroupSchema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          required: true,
          maxlength: 32,
          validator: VueFormGenerator.validators.string,
          styleClasses: "col-sm-9"
        },
        {
          type: "select",
          inputType: "text",
          label:
            "<b>Status</b>",
          model: "state",
          required: true,
          default: "EDITING",
          styleClasses: "col-sm-6",
          values: [
            { id: "CLOSED", name: "abgeschlossen" },
            { id: "EDITING", name: "in Bearbeitung" },
            { id: "OPERABLE", name: "Mails versandt" }
          ]
        }
      ]
    }
  };
}

export default {
  name: "EditEvent",
  components: {},
  data: getDefaultData,

  methods: {
    onValidate: function () {
      var errors = this.$refs.registerForm.validate();
      console.log("Validated", errors);
      if (errors) {
        this.submitData(this.$data);
      }
    },
    restartForm() {
      Object.assign(this.$data, this.getDefaultData());
    },
    submitData(payload) {
      console.log(payload.model);
      payload.wizardStatus.seen = false;
      payload.wizardStatus.loading = true;
      if (this.mode == "new") {
        apiService
          .createEvent(payload.model)
          .then(function (response) {
            if (typeof response === "undefined" || response === null) {
              payload.wizardStatus.errorMsg = true;
              payload.wizardStatus.seen = false;
              console.log("Da ist was schief gelaufen. No response object.");
            } else {
              if (response.status == 200) {
                //payload.wizardStatus.seen = false;
                payload.wizardStatus.doneMsg = true;
                Object.assign(payload, getDefaultData());
              } else {
                payload.wizardStatus.seen = false;
                payload.wizardStatus.errorMsg = true;
              }
              console.log(
                "Status: " + response.status + "::" + response.statusText
              );
            }
            payload.wizardStatus.loading = false;
          });
      } else if (this.mode == "edit") {
        apiService
          .editEvent(payload.model)
          .then(function (response) {
            if (typeof response === "undefined" || response === null) {
              payload.wizardStatus.errorMsg = true;
              payload.wizardStatus.seen = false;
              console.log("Da ist was schief gelaufen. No response object.");
            } else {
              if (response.status == 200) {
                //payload.wizardStatus.seen = false;
                payload.wizardStatus.doneMsg = true;
                //Object.assign(payload, getDefaultData());
              } else {
                payload.wizardStatus.seen = false;
                payload.wizardStatus.errorMsg = true;
              }
              console.log(
                "Status: " + response.status + "::" + response.statusText
              );
            }
            payload.wizardStatus.loading = false;
          });
      }
    }
  },
  created() {
    if (this.$route.params.event) {
      this.mode = "edit";
      this.model = this.$route.params.event;
    }
    console.log("Mode: " + this.mode);
  }
};
</script>

<style scoped>
html {
  font-family: Tahoma;
  font-size: 14px;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

h1 {
  text-align: center;
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

fieldset {
  border: 5;
  text-align: left;
  display: inline;
}

.display-inline label {
  display: inline !important;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}

.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;

  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}

.field-checklist .wrapper {
  width: 100%;
}
</style>