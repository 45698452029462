var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.eventPartName)+" "),_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/pdf-icon.png"),"width":"35pt"},on:{"click":function($event){return _vm.getEventplan()}}})]),_c('h2',[_vm._v(" Bereichsgruppen "),(this.$route.params.state != 'CLOSED')?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.addSectionGroup(_vm.$route.params.id)}}},[_vm._v("+")]):_vm._e()]),_c('table',{staticClass:"table table-bordered table-hover table-striped"},[_vm._m(0),_c('tbody',[_vm._l((_vm.sectionGroups),function(group){return _c('tr',{key:group.id},[_c('th',{attrs:{"scope":"row"}},[_c('router-link',{attrs:{"to":{ name: 'sectionGroup', params: { group: group, partName: _vm.eventPartName }}}},[_vm._v(_vm._s(group.name))]),_vm._v("  "),_c('b-button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.editGroup(group)}}},[_vm._v("o")]),_vm._v("  "),_c('b-button',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                      loader: false,
                      ok: dialog => _vm.deleteGroup(dialog, group),
                      cancel: _vm.doNothing,
                      message: group.name + ' wirklich löschen?'}),expression:"{\n                      loader: false,\n                      ok: dialog => deleteGroup(dialog, group),\n                      cancel: doNothing,\n                      message: group.name + ' wirklich löschen?'}"}],staticClass:"btn btn-danger",attrs:{"icon":""}},[_vm._v("X")])],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'availablePersons', params: { id: _vm.$route.params.id, group: group, partName: _vm.eventPartName }}}},[_vm._v(_vm._s(_vm.workHelperCount[group.id]))])],1),_c('td',[_vm._v(_vm._s(group.jobCount))]),_c('td',[_vm._v(_vm._s((group.jobCount - group.assignmentCount))+" ")])])}),_c('tr',[_vm._m(1),_c('td'),_c('td',{staticClass:"sum"},[_vm._v(_vm._s(_vm.totalJobCount))]),_c('td',{staticClass:"sum"},[_vm._v(_vm._s(_vm.totalMissingCount))])])],2)]),_c('h2',[_vm._v("Helferliste")]),_c('div',_vm._l((_vm.persons),function(person){return _c('span',{key:person.uniqueId},[_c('span',{style:(_vm.getBgColor(person.assignmentCount))},[_c('router-link',{attrs:{"to":{ name: 'person', params: { id: person.id, name: person.displayName }}}},[_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(person.displayName))])])],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',[_vm._v("gemeldete Helfer")]),_c('th',[_vm._v("Jobs")]),_c('th',[_vm._v("unbesetzte Jobs")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"scope":"row"}},[_c('b',[_vm._v("Summe")])])
}]

export { render, staticRenderFns }