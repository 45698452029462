<template>
  <div>
    <h1>Offene Änderungen - {{ this.$route.params.name }}
    <img style="cursor: pointer" v-confirm="{
      loader: false,
      ok: dialog => sendEventMails(),
      cancel: doNothing,
      message: 'Mails wirklich versenden?',
      buttons: { yes: 'yes', no: 'no' }
    }" src="../assets/mail.png" width="35pt" />
    </h1>
    <i>Diese Seite zeigt die offenen Änderungen die seit dem letzten Mailversand für diese Veranstaltung vorgenommen
      wurden.</i>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th>Tag</th>
          <th>Bereich</th>
          <th>Position</th>
          <th>Schicht</th>
          <th>Hinzugefügt?</th>
          <th>Wer?</th>
          <th>Wann?</th>
          <th>Löschen?</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="assignment in assignments" :key="assignment.id">
          <td><router-link
              :to="{ name: 'person', params: { id: assignment.person.id, name: assignment.person.displayName } }">{{
                assignment.person.displayName}}</router-link></td>
          <td><router-link
              :to="{ name: 'eventPart', params: { id: assignment.shiftAssignment.shift.eventPartId, partName: assignment.shiftAssignment.shift.eventPartName } }">{{
                assignment.shiftAssignment.shift.eventPartName }}</router-link>
          </td>
          <td>{{ assignment.shiftAssignment.shift.sectionName }}</td>
          <td>{{ assignment.shiftAssignment.positionName }}</td>
          <td>{{ assignment.shiftAssignment.shift.name }}</td>
          <td v-if="assignment.isDeleted == false"><span style="color: #20bba0">Neu</span></td>
          <td v-if="assignment.isDeleted == true"><span style="color: #ff0000">Gelöscht</span></td>
          <td>{{ assignment.creationUser }}</td>
          <td>{{ assignment.createdAt | formatDate }}</td>
          <td>
            <b-button v-if="assignment.isDeleted == false" class="btn btn-danger" icon
              v-confirm="{
                loader: false,
                ok: dialog => deleteAssignment(dialog, assignment),
                cancel: doNothing,
                message: assignment.person.displayName + ' an ' + assignment.shiftAssignment.positionName + ' wirklich löschen?'
              }">X</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";
import moment from "moment";

const apiService = new APIService();

export default {
  name: "AssignmentsView",

  components: {},

  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(value).format("DD.MM.YYYY HH:mm");
      }
    }
  },

  data() {
    return {
      assignments: []
    };
  },
  methods: {
    doNothing: function () {
      // Do nothing
      console.log("No");
    },
    sendEventMails() {
      this.$router.push({
        name: "mailstatus",
        params: { eventId: this.$route.params.id, eventName: this.$route.params.name }
      });
    },    
    deleteAssignment(dialog, asm) {
      apiService.deleteDirtyAssignmentById(asm.id).then(response => {
        if (response.status == 200) {
          const index = this.assignments.findIndex(post => post.id === asm.id); // find the section index
          if (~index)
            // if the post exists in array
            this.assignments.splice(index, 1); //delete the section
        }
      });
      dialog.close();
    }
  },
  created() {
    apiService.getDirtyAssignments(this.$route.params.id).then(data => {
      this.assignments = data;
    });
  }
};
</script>