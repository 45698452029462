<template>
    <span :style="getHintColor(state)">{{ getHintText(state) }}</span>
</template>

<script>
export default {
  name: "EventState",
  props: ['state'],
  data() {
    return {
    }
  },
  methods: {
    getHintText(state) {
      if(state == "EDITING") {
        return "in Bearbeitung";
      } else if (state == "OPERABLE") {
        return "Mails versandt";
      } else if (state == "CLOSED") {
        return "abgeschlossen";
      }
    },
    getHintColor(state) {
      if(state == "EDITING") {
        return "color: #008C2E;";
      } else if (state == "CLOSED") {
        return "color: #010101;";
      } else if (state == "OPERABLE") {
        return "color: #ff0000;";
      }
    }
  }
}
</script>