var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(_vm._s(this.$route.params.id)+": "+_vm._s(_vm.personData.displayName)+" "),_c('button',{staticClass:"btn btn-warning",attrs:{"size":"sm"},on:{"click":function($event){return _vm.editPerson(_vm.personData)}}},[_vm._v("o")])]),_c('table',{staticClass:"table table-bordered table-hover table-striped"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v("Prio 1:")]),_c('td',[_vm._v(_vm._s(_vm.personData.thA))])]),_c('tr',[_c('td',[_vm._v("Prio 2:")]),_c('td',[_vm._v(_vm._s(_vm.personData.thE))])]),_c('tr',[_c('td',[_vm._v("Alter: "),_c('AgeHint',{attrs:{"ageId":_vm.personData.age}})],1),_c('td',[_vm._v("Team-Head: "+_vm._s(_vm.teamHead))])]),_c('tr',[_c('td',[_vm._v("T-Shirt Schnitt: "+_vm._s(_vm.gender))]),_c('td',[_vm._v("T-Shirt Größe: "+_vm._s(_vm.shirtSize))])]),_vm._m(1),_c('tr',[_c('td',{staticStyle:{"font-style":"italic"},attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.personData.hint))])])])]),_c('br'),_c('div',{staticStyle:{"text-align":"left"}},[_vm._v("  Anzahl Teammitglieder: "+_vm._s(_vm.members.length))]),_c('table',{staticClass:"table table-bordered table-hover table-striped"},[_vm._m(2),_c('tbody',_vm._l((_vm.members),function(member){return _c('tr',{key:member.id},[_c('td',[_vm._v(_vm._s(member.id))]),_c('td',[_vm._v(_vm._s(member.displayName))]),_c('td',[_c('b-button',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                      loader: false,
                      ok: dialog => _vm.deleteMember(dialog, member),
                      cancel: _vm.doNothing,
                      message: member.lastName + member.firstName +' wirklich löschen?'}),expression:"{\n                      loader: false,\n                      ok: dialog => deleteMember(dialog, member),\n                      cancel: doNothing,\n                      message: member.lastName + member.firstName +' wirklich löschen?'}"}],staticClass:"btn btn-danger",attrs:{"icon":""}},[_vm._v("X")])],1)])}),0)]),_c('br'),_c('div',{staticStyle:{"text-align":"left"}},[_vm._v("  Anzahl Einteilungen: "+_vm._s(_vm.assignments.length))]),_c('table',{staticClass:"table table-bordered table-hover table-striped"},[_vm._m(3),_c('tbody',_vm._l((_vm.assignments),function(assignment){return _c('tr',{key:assignment.id},[_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.shift.eventName))]),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.shift.eventPartName))]),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.shift.sectionName))]),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.positionName))]),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.shift.name))])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Wunsch")]),_c('th',[_vm._v("Info")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Hinweise")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Id")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Löschen?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Veranstaltung")]),_c('th',[_vm._v("Tag")]),_c('th',[_vm._v("Bereich")]),_c('th',[_vm._v("Position")]),_c('th',[_vm._v("Schicht")])])])
}]

export { render, staticRenderFns }