var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Offene Änderungen - "+_vm._s(this.$route.params.name)+" "),_c('img',{directives:[{name:"confirm",rawName:"v-confirm",value:({
    loader: false,
    ok: dialog => _vm.sendEventMails(),
    cancel: _vm.doNothing,
    message: 'Mails wirklich versenden?',
    buttons: { yes: 'yes', no: 'no' }
  }),expression:"{\n    loader: false,\n    ok: dialog => sendEventMails(),\n    cancel: doNothing,\n    message: 'Mails wirklich versenden?',\n    buttons: { yes: 'yes', no: 'no' }\n  }"}],staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/mail.png"),"width":"35pt"}})]),_c('i',[_vm._v("Diese Seite zeigt die offenen Änderungen die seit dem letzten Mailversand für diese Veranstaltung vorgenommen wurden.")]),_c('table',{staticClass:"table table-bordered table-hover table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.assignments),function(assignment){return _c('tr',{key:assignment.id},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'person', params: { id: assignment.person.id, name: assignment.person.displayName } }}},[_vm._v(_vm._s(assignment.person.displayName))])],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'eventPart', params: { id: assignment.shiftAssignment.shift.eventPartId, partName: assignment.shiftAssignment.shift.eventPartName } }}},[_vm._v(_vm._s(assignment.shiftAssignment.shift.eventPartName))])],1),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.shift.sectionName))]),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.positionName))]),_c('td',[_vm._v(_vm._s(assignment.shiftAssignment.shift.name))]),(assignment.isDeleted == false)?_c('td',[_c('span',{staticStyle:{"color":"#20bba0"}},[_vm._v("Neu")])]):_vm._e(),(assignment.isDeleted == true)?_c('td',[_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("Gelöscht")])]):_vm._e(),_c('td',[_vm._v(_vm._s(assignment.creationUser))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(assignment.createdAt)))]),_c('td',[(assignment.isDeleted == false)?_c('b-button',{directives:[{name:"confirm",rawName:"v-confirm",value:({
              loader: false,
              ok: dialog => _vm.deleteAssignment(dialog, assignment),
              cancel: _vm.doNothing,
              message: assignment.person.displayName + ' an ' + assignment.shiftAssignment.positionName + ' wirklich löschen?'
            }),expression:"{\n              loader: false,\n              ok: dialog => deleteAssignment(dialog, assignment),\n              cancel: doNothing,\n              message: assignment.person.displayName + ' an ' + assignment.shiftAssignment.positionName + ' wirklich löschen?'\n            }"}],staticClass:"btn btn-danger",attrs:{"icon":""}},[_vm._v("X")]):_vm._e()],1)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',[_vm._v("Tag")]),_c('th',[_vm._v("Bereich")]),_c('th',[_vm._v("Position")]),_c('th',[_vm._v("Schicht")]),_c('th',[_vm._v("Hinzugefügt?")]),_c('th',[_vm._v("Wer?")]),_c('th',[_vm._v("Wann?")]),_c('th',[_vm._v("Löschen?")])])])
}]

export { render, staticRenderFns }