<template>
  <div class="hello">
    <h1>Veranstaltungen ({{ numberOfEvents }})&nbsp;<button class="btn btn-primary" @click="addEvent()">+</button></h1>

  <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Id</th>
          <th>Name</th>
          <th>Status</th>
          <th>offene Änderungen</th>
          <th>Aktionen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{ event.id }}</td>
          <td scope="row">
            <router-link
              :to="{ name: 'structure', params: { id: event.id, name: event.name, state: event.state }}"
            >{{ event.name }}</router-link>&nbsp;
            &nbsp;<b-button class="btn btn-warning" @click="showStructurePlan(event)">S</b-button>&nbsp;
            &nbsp;<b-button class="btn btn-warning" @click="showAssignmentPlan(event)">E</b-button>&nbsp;

          </td>
          <td>
            <EventState :state="event.state" />
          </td>
          <td>
            <router-link v-if="event.state == 'OPERABLE'" :to="{ name: 'dirtyAssignments', params: { id: event.id, name: event.name }}">
              {{ dirtyLengths[event.id] }}</router-link>
            <span v-else>-</span>
          </td> 
          <td>
            <b-button class="btn btn-warning" @click="editEvent(event)">o</b-button>&nbsp;
            <b-button
              class="btn btn-danger"
              icon
              v-confirm="{
                        loader: false,
                        ok: dialog => deleteEvent(dialog, event),
                        cancel: doNothing,
                        message: event.name + ' wirklich löschen?'}"
            >X</b-button>
          </td>         
          <!--<td>{{ group.jobCount }}</td>
          <td>{{ (group.jobCount - group.assignmentCount) }} </td>-->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";
import EventState from "../components/EventState.vue";
import Vue from "vue";

const apiService = new APIService();

export default {
  name: "Events",

  props: {
    msg: String
  },

  components: {EventState},

  data() {
    return {
      itemToDelete: null,
      fields: [
        { key: "id", sortable: true },
        {
          key: "name",
          sortable: true
        },
        { key: "actions", label: "Aktionen" }
      ],
      headVariant: "dark",
      filterOn: ["id", "name"],
      events: [],
      dirtyLengths: [],
      numberOfEvents: 0,
      totalRows: 0,
      perPage: 25,
      filter: "",
      currentPage: 1
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addEvent() {
      this.$router.push({
        name: "editEvent"
      });
    },
    getDirtyLength(eventId) {
      apiService.getDirtyAssignments(eventId).then(data => {
        Vue.set(this.dirtyLengths, eventId, data.length);
      });
    },
    editEvent(event) {
      this.$router.push({
        name: "editEvent",
        params: {
          event: event
        }
      });
    },    
    deleteEvent(dialog, event) {
      apiService.deleteEvent(event).then(response => {
        if (response.status == 200) {
          const index = this.events.findIndex(post => post.id === event.id); // find the event index
          if (~index)
            // if the event exists in array
            this.events.splice(index, 1); // remove the event
        }
      });
      dialog.close();
    },
    showStructurePlan(event) {
      this.$router.push({
        name: "workplan",
        params: {
          id: event.id
        }
      });
    },
    showAssignmentPlan(event) {
      this.$router.push({
        name: "Workplan2",
        params: {
          id: event.id
        }
      });
    },    
    getEvents() {
      apiService.getEvents().then(data => {
        this.events = data;
        this.numberOfEvents = data.length;
        this.totalRows = data.length;
        data.forEach(event => {
            if(event.state == "OPERABLE") {
              this.getDirtyLength(event.id);
            }
          });
        console.log("Count: " + this.numberOfEvents + ": " + this.events[0]);
      });
    },
    doNothing: function() {
      // Do nothing
      console.log("No");
    }
  },

  mounted() {
    this.getEvents();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>