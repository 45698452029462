/* eslint-disable */
<template>
  <div class="hello">
    <h1>
      <span v-if="mode == 'new'">Helfer Registrierung</span>
      <span v-if="mode == 'edit'">Helfer ändern</span>
    </h1>

    <div class="container" id="app" v-if="wizardStatus.seen">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="panel-body">
            <vue-form-generator
              :model="model"
              :schema="teamSchema"
              :options="formOptions"
              ref="registerForm"
            ></vue-form-generator>

            <button class="btn btn-lg btn-primary" @click="onValidate">Speichern</button>
          </div>
        </div>
      </div>
    </div>

    <div id="doneScreen" v-if="wizardStatus.doneMsg">
      <b>Vielen Dank für deine Anmeldung als Helfer beim Kreismusikfest ZWANZIG ZWANZIG in Mietingen.</b>
      <br />In Kürze erhälst du eine Bestätigung deiner Anmeldung per eMail (bitte ggf. Spam-Filter prüfen!).
    </div>
    <div id="doneScreen" v-if="wizardStatus.updateMsg">
      <b>Daten wurden erfolgreich geändert.</b>
    </div>
    <div id="doneScreen" v-if="wizardStatus.errorMsg">
      <b>Hoppla, da ist was schief gelaufen.</b>
      <br />Versuche es bitte später noch einmal. Sollte die Anmeldung weiterhin nicht funktionieren wende
      dich bitte an
      <a
        href="mailto:webmaster@mietingen2020.de"
      >webmaster@mietingen2020.de</a>.
    </div>
    <div id="loadingScreen" v-if="wizardStatus.loading">
      <h2>Anmeldung wird gespeichert...</h2>
      <img alt="Loading..." style="width: 26%" src="../assets/loadinganimation.gif" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueFormGenerator from "vue-form-generator";

import { APIService } from "../APIService";

import "vue-form-generator/dist/vfg.css";

Vue.use(VueFormGenerator);

VueFormGenerator.validators.resources.fieldIsRequired =
  "Dieses Feld ist ein Pflichtfeld.";

const apiService = new APIService();

function getDefaultData() {
  return {
    mode: "new",
    wizardStatus: {
      seen: true,
      doneMsg: false,
      errorMsg: false,
      loading: false,
      updateMsg: false
    },
    model: {
      registerType: "workSingle",
      fr: "none",
      sa: "none",
      tu: "none",
      we: "none",
      thA: "none",
      thE: "none",
      frA: "none",
      frE: "none",
      saA: "none",
      saE: "none",
      soM: "none",
      moA: "none",
      moE: "none",   
      firstName: "",
      lastName: "",
      email: "",
      streetName: "",
      city: "",
      phone: "",
      groupName: "",
      age: "",
      cakeTh: "0",
      cakeSu: "0",
      cakeMo: "0",      
      parade: "",
      size: "",
      gender: "",
      hint: ""
    },
    formOptions: {
      validationErrorClass: "has-error",
      validationSuccessClass: "has-success",
      validateAfterChanged: false,
      validateAfterLoad: false
    },
    timeSelectionSchema: {
      fields: []
    },
    teamSchema: {
      groups: [
        {
          legend: "Personendaten",
          fields: [
            {
              type: "input",
              inputType: "text",
              label: "Vorname",
              model: "firstName",
              required: true,
              maxlength: 32,
              validator: VueFormGenerator.validators.string,
              styleClasses: "col-sm-3"
            },
            {
              type: "input",
              inputType: "text",
              label: "Nachname",
              model: "lastName",
              required: true,
              maxlength: 32,
              validator: VueFormGenerator.validators.string,
              styleClasses: "col-sm-3"
            },
            {
              type: "input",
              inputType: "text",
              label: "Wohnort",
              model: "city",
              required: false,
              maxlength: 32,
              validator: VueFormGenerator.validators.string,
              styleClasses: "col-sm-3"
            },
            {
              type: "input",
              inputType: "text",
              label: "Email",
              model: "email",
              required: false,
              maxlength: 64,
              validator: VueFormGenerator.validators.email,
              styleClasses: "col-sm-3"
            },
            {
              type: "input",
              inputType: "text",
              label: "Telefon/Handy",
              model: "phone",
              required: false,
              maxlength: 32,
              validator: VueFormGenerator.validators.string,
              styleClasses: "col-sm-3"
            },
            {
              type: "input",
              inputType: "text",
              label: "Verein/Gruppe",
              model: "groupName",
              required: false,
              maxlength: 64,
              validator: VueFormGenerator.validators.string,
              styleClasses: "col-sm-3"
            },
            {
              type: "select",
              inputType: "text",
              label: "Alter",
              model: "age",
              required: true,
              styleClasses: "col-sm-2",
              validator: VueFormGenerator.validators.string,
              values: [
                { name: "unter 16 Jahre", id: "1" },
                { name: "zwischen 16 und 18 Jahre", id: "2" },
                { name: "über 18 Jahre", id: "3" }
              ]
            }
          ]
        },
        {
          legend: "T-Shirt",
          fields: [
            {
              type: "select",
              inputType: "text",
              label: "T-Shirt Schnitt",
              model: "gender",
              required: false,
              styleClasses: "col-sm-2",
              validator: VueFormGenerator.validators.string,
              values: [
                { name: "Männlich", id: "0" },
                { name: "Weiblich", id: "1" }
              ]
            },
            {
              type: "select",
              inputType: "text",
              label: "T-Shirt Größe",
              model: "size",
              required: false,
              styleClasses: "col-sm-2",
              validator: VueFormGenerator.validators.string,
              values: [
                { name: "XS", id: "1" },
                { name: "S", id: "2" },
                { name: "M", id: "3" },
                { name: "L", id: "4" },
                { name: "XL", id: "5" },
                { name: "XXL", id: "6" },
                { name: "3XL", id: "7" },
                { name: "98/104", id: "11" },
                { name: "110/116", id: "12" },
                { name: "122/128", id: "13" },
                { name: "134/146", id: "14" },
                { name: "152/164", id: "15" }
              ]
            }
          ]
        },
        {
          legend: "Dienste",
          fields: [
            {
              type: "select",
              inputType: "text",
              label:
                "<b>Prio 1</b>",
              model: "thA",
              styleClasses: "col-sm-6",
              values: [
              { id: "flex", name: "Ich bin flexibel" },
                { id: "construction", name: "Umbau & Bestuhlen" },
                { id: "kitchen", name: "Küche" },
                { id: "drinks", name: "Ausschank" },
                { id: "bar", name: "Bar" },
                { id: "coffee", name: "Kaffee/Kuchen" },
                { id: "service", name: "Essens- & Getränke Träger" },
                { id: "none", name: "gar nicht" }
              ]
            },
            {
              type: "select",
              inputType: "text",
              label: "<b>Prio 2</b>",
              model: "thE",
              styleClasses: "col-sm-6",
              default: "none",
              values: [
                { id: "flex", name: "Ich bin flexibel" },
                { id: "construction", name: "Umbau & Bestuhlen" },
                { id: "kitchen", name: "Küche" },
                { id: "drinks", name: "Ausschank" },
                { id: "bar", name: "Bar" },
                { id: "coffee", name: "Kaffee/Kuchen" },
                { id: "service", name: "Essens- & Getränke Träger" },
                { id: "none", name: "gar nicht" }
              ]
            }
          ]
        },
        {
          legend: "Hinweise",
          fields: [
            {
              type: "textArea",
              model: "hint",
              max: 254,
              rows: 6,
              required: false,
              validator: VueFormGenerator.validators.string
            }
          ]
        }
        /*{
            legend: "Speichern",
            fields: [
              {
                type: "submit",
                buttonText: "Abschicken",
                validateBeforeSubmit: true,
                onSubmit(model) {
                  console.log("Form ready to submit!", model);
                  //apiService.createEvent(model);
                  //this.submitData(model);
                },
                onValidationError(model) {
                  console.log("Form not successfully validated!", model);
                }
              }
            ]
          }*/
      ]
    }
  };
}

export default {
  name: "RegisterView",
  components: {},
  data: getDefaultData,

  methods: {
    onValidate: function() {
      var errors = this.$refs.registerForm.validate();
      console.log("Validated", errors);
      if (errors) {
        this.submitData(this.$data);
      }
    },
    restartForm() {
      Object.assign(this.$data, this.getDefaultData());
    },
    submitData(payload) {
      console.log(payload.model);
      payload.wizardStatus.seen = false;
      payload.wizardStatus.loading = true;
      if (this.mode == "new") {
        apiService.createPersonNew(payload.model).then(function(response) {
          if (typeof response === "undefined" || response === null) {
            payload.wizardStatus.errorMsg = true;
            payload.wizardStatus.seen = false;
            console.log("Da ist was schief gelaufen. No response object.");
          } else {
            if (response.status == 200) {
              //payload.wizardStatus.seen = false;
              payload.wizardStatus.doneMsg = true;
              Object.assign(payload, getDefaultData());
            } else {
              payload.wizardStatus.seen = false;
              payload.wizardStatus.errorMsg = true;
            }
            console.log(
              "Status: " + response.status + "::" + response.statusText
            );
          }
          payload.wizardStatus.loading = false;
        });
      } else if (this.mode == "edit") {
        apiService.editPerson(payload.model).then(function(response) {
          if (typeof response === "undefined" || response === null) {
            payload.wizardStatus.errorMsg = true;
            payload.wizardStatus.seen = false;
            console.log("Da ist was schief gelaufen. No response object.");
          } else {
            if (response.status == 200) {
              //payload.wizardStatus.seen = false;
              payload.wizardStatus.updateMsg = true;
              //Object.assign(payload, getDefaultData());
            } else {
              payload.wizardStatus.seen = false;
              payload.wizardStatus.errorMsg = true;
            }
            console.log(
              "Status: " + response.status + "::" + response.statusText
            );
          }
          payload.wizardStatus.loading = false;
        });
      }
      //alert("Done");
    }
  },
  mounted() {
    if (this.$route.params.person) {
      this.mode = "edit";
      this.model = this.$route.params.person;
    }
    console.log("Mode: " + this.mode);
  }
};
</script>

<style scoped>
html {
  font-family: Tahoma;
  font-size: 14px;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

h1 {
  text-align: center;
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

fieldset {
  border: 5;
  text-align: left;
  display: inline;
}

.display-inline label {
  display: inline !important;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}

.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;

  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}

.field-checklist .wrapper {
  width: 100%;
}
</style>