<template>
    <span :style="getHintColor(ageId)">{{ getHintText(ageId) }}</span>
</template>

<script>
export default {
  name: "AgeHint",
  props: ['ageId'],
  data() {
    return {
    }
  },
  methods: {
    getHintText(ageId) {
      if(ageId == 3) {
        return "Ü18";
      } else if (ageId == 2) {
        return "Ü16";
      } else if (ageId == 1) {
        return "U16";
      }
    },
    getHintColor(ageId) {
      if(ageId == 3) {
        return "color: #008C2E;";
      } else if (ageId == 2) {
        return "color: #FFAB0F;";
      } else if (ageId == 1) {
        return "color: #ff0000;";
      }
    }
  }
}
</script>