<template>
  <div class="hello">
    <h1>
      Helfer
      <router-link
        :to="{ name: 'eventPart', params: { id: $route.params.id, partName: $route.params.partName }}"
      >{{ $route.params.partName }}</router-link>&nbsp;/
      <router-link
        :to="{ name: 'sectionGroup', params: { group: $route.params.group, partName: $route.params.partName }}"
      >{{ $route.params.group.name }}</router-link>
      - {{ numberOfEvents }}
    </h1>

    <b-form-fieldset label-text-align="right" horizontal label="Filter:" class="col-4">
      <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Filtertext"></b-form-input>
    </b-form-fieldset>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="persons-table"
    ></b-pagination>

    <b-table
      show-empty
      id="persons-table"
      :items="persons"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :head-variant="headVariant"
      :filter-included-fields="filterOn"
      class="table table-bordered table-hover table-striped"
      @filtered="onFiltered"
    >
      <template v-slot:cell(firstName)="row">
        <router-link
          :to="{ name: 'person', params: { id: row.item.id, name: row.item.displayName }}"
        >{{ row.item.firstName }}</router-link>
      </template>
      <template v-slot:cell(lastName)="row">
        <router-link
          :to="{ name: 'person', params: { id: row.item.id, name: row.item.displayName }}"
        >{{ row.item.lastName }}</router-link>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="persons-table"
    ></b-pagination>
  </div>
</template>

<script>
import { APIService } from "../APIService";

const apiService = new APIService();

export default {
  name: "AvailablePersons",

  components: {},

  data() {
    return {
      fields: [
        { key: "id", sortable: true },
        {
          key: "firstName",
          sortable: true
        },
        {
          key: "lastName",
          sortable: true
        },
        { key: "city", sortable: true },
        { key: "groupName", sortable: true }
      ],
      headVariant: "dark",
      filterOn: ["id", "firstName", "lastName", "groupName"],
      persons: [],
      numberOfEvents: 0,
      totalRows: 0,
      perPage: 100,
      filter: "",
      currentPage: 1
    };
  },
  watch: {
    /*filter(newVal, oldVal) {
      console.log("watch filter:", newVal, oldVal);
    }*/
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getEvents() {
      apiService
        .getPersons(
          this.$route.params.id,
          this.$route.params.group.workType,
          false,
          false
        )
        .then(data => {
          this.persons = data;
          this.numberOfEvents = data.length;
          this.totalRows = data.length;
        });
    },
    doNothing: function() {
      // Do nothing
      console.log("No");
    }
  },

  mounted() {
    this.getEvents();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
